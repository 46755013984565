import React, { useMemo } from "react"
import { Hide, Show, Image, ImageProps } from "@chakra-ui/react"
import { useImage } from "@app/hooks/useImage"

type Props = {
  image: GatsbyTypes.Maybe<GatsbyTypes.SanityImageResponsive>
  breakpoint: string
  widths?: number[]
  imageProps: ImageProps
}

const ResponsiveImage: React.FC<Props> = ({ image, breakpoint, widths, imageProps }) => {
  const { getGatsbyImage } = useImage()

  const desktopWidth = useMemo(() => (widths?.length ? widths[0] : 2000), [widths])
  const mobileWidth = useMemo(() => (widths?.length && widths.length > 1 ? widths[1] : 1000), [widths])
  const desktop = useMemo(() => getGatsbyImage(image?.desktop, { width: desktopWidth }), [getGatsbyImage, image?.desktop, desktopWidth])
  const mobile = useMemo(() => getGatsbyImage(image?.mobile, { width: mobileWidth }), [getGatsbyImage, image?.mobile, mobileWidth])

  const desktopImage = useMemo(() => <Image {...desktop} {...imageProps} />, [desktop, imageProps])
  const mobileImage = useMemo(() => <Image {...mobile} {...imageProps} />, [mobile, imageProps])

  return desktop && mobile ? (
    <>
      <Hide above={breakpoint}>{mobileImage}</Hide>
      <Show above={breakpoint}>{desktopImage}</Show>
    </>
  ) : desktop && !mobile ? (
    desktopImage
  ) : mobile && !desktop ? (
    mobileImage
  ) : null
}
export default React.memo(ResponsiveImage)
