import React from "react"
import { As, Box, Heading } from "@chakra-ui/react"

import ResponsiveImage from "@app/components/ResponsiveImage"

type Props = {
  title: string | undefined
  image: GatsbyTypes.SanityImageResponsive | undefined
  headingTag?: As<any> | undefined
}

const SimpleHero: React.FC<Props> = ({ image, title, headingTag = "h2" }) => {
  return image ? (
    <Box as="section" pos="relative">
      <Box pos="relative" pb={{ base: "clamp(12.5rem, 53.19%, 18.75rem)", lg: "75" }}>
        <ResponsiveImage
          image={image}
          breakpoint="lg"
          imageProps={{ pos: "absolute", top: "0", left: "0", w: "full", h: "full", objectFit: "cover" }}
        />
      </Box>

      <Box
        pos="absolute"
        top="0"
        left="0"
        d="flex"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        w="full"
        h="full"
        zIndex="dropdown"
      >
        {title && (
          <Heading as={headingTag} size="h1" color="background.white" maxW={{ base: "52.5", lg: "none" }} textAlign="center">
            {title}
          </Heading>
        )}
      </Box>
    </Box>
  ) : null
}

export default React.memo(SimpleHero)
